






import {
  Component,
  Inject,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { JanusServerService } from '@/services/CameraServiceJanus';

interface VbVideoStream extends Vue {
  $refs: {
    videoElement: HTMLVideoElement;
  };
}

@Component({})
class VbVideoStream extends Vue {
  @Inject() janusService!: JanusServerService;
  @Prop({ required: true }) videoId!: number;

  async mounted() {
    await this.janusService.initialize();
    await this.$nextTick();
    if (!this.$refs.videoElement) {
      return;
    }
    this.janusService.startStream(Number(this.videoId), this.$refs.videoElement);
  }

  destroyed() {
    this.janusService.stopStream();
  }
}

export default VbVideoStream;
