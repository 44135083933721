













import Vue from 'vue';
import {
  Component,
  InjectReactive,
} from 'vue-property-decorator';
import VbDialog from '@/components/VbDialog.vue';
import { Polygon } from '@/models/entities/Polygon';
import LoadingContent from '@/components/LoadingContent.vue';
import VbVideoStream from '@/components/VbVideoStream.vue';
import { Camera } from '@/models/entities/Camera';

@Component({
  components: {
    LoadingContent,
    VbDialog,
    VbVideoStream,
  },
})
export default class PolygonCamsStreamView extends Vue {
  @InjectReactive() polygon!: Polygon;
  @InjectReactive() cameras!: Camera[];
  private _isLoading: boolean = false;
  private streamId: string = '';

  mounted() {
    this._isLoading = true;
    this.streamId = this.$route.params.streamId;
    this._isLoading = false;
  }

  get isLoading(): boolean {
    return this._isLoading || this.camera === null;
  }

  get camera(): Camera | null {
    return (this.streamId && this.cameras.find((c) => c?.cameraVideoId === this.streamId)) || null;
  }

  get title(): string {
    return this.camera?.cameraName || '';
  }

  handleClose() {
    this.$router.back();
  }
}
